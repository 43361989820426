import React, { useEffect } from 'react';

function NotFoundPage() {
  useEffect(() => {
    window.location.href = '/';
    console.log('bejön');
  }, []);

  return <div>404</div>;
}

export default NotFoundPage;
